<template>
    <div>
        <actions-create
            v-if="$can('create', 'actions') && showCreateComponent"
            id="create-actions-modal"
            :actionCategories="actionCategories"
            :goalCategories="goalCategories"
            @addNewactionToList="(action) => rows.unshift(action)"
        >
        </actions-create>
        <b-card>
            <datatable-header
                :title="$tc('modules.actions.description', 2)"
                :genericSearchEnabled="true"
                :searchTermPlaceHolder="$t('common.search')"
                @filterUpdated="onFilterUpdate"
            >
                <template #buttons>
                    <b-button
                        class="ml-2"
                        variant="primary"
                        style="background-color: #519bd6"
                        @click="
                            $root.$emit(
                                'bv::toggle::modal',
                                'create-actions-modal'
                            )
                        "
                    >
                        {{
                            $t("common.crud.addNew", {
                                resource: $tc(
                                    "modules.actions.description",
                                    1
                                ).toLowerCase(),
                            }).toString()
                        }}
                    </b-button>
                </template>

                <template #advancedSearch="{ expanded, onFilterChange }">
                    <DatatableFilter
                        :availableDatatableFilters="availableDatatableFilters"
                        :expanded="expanded"
                        @filterUpdated="onFilterUpdate"
                        @onFilterChange="onFilterChange"
                    ></DatatableFilter>
                </template>
            </datatable-header>

            <datatable
                :columns="columns"
                :isLoading="isLoading"
                :paginationOptions="{
                    enabled: true,
                    mode: 'pages',
                    perPage: dataTableOptions.pagination.perPage,
                }"
                :rows="rows"
                :rtl="direction"
                :searchOptions="{
                    enabled: true,
                    externalQuery: searchTerm,
                }"
                :totalRows="dataTableOptions.pagination.totalRows"
                mode="remote"
                theme="my-theme"
                styleClass="vgt-table"
                @on-row-click="onRowClick"
                @on-page-change="handleChangePage"
                @onPageSizeChange="handlePageSizeChange"
            >
                <template #table-row="props">
                    <span v-if="props.column.field === 'actionCategory'">
                        <span v-if="props.row.actionCategory">
                            {{ props.row.actionCategory.description }}
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'goalCategory'">
                        <span v-if="props.row.goalCategory">
                            {{ props.row.goalCategory.description }}
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'budget'">
                        <span v-if="props.row.budget">
                            {{ $n(props.row.budget, "currency") }}
                        </span>
                    </span>

                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </datatable>
        </b-card>
    </div>
</template>

<script>
import { BCard, BButton } from "bootstrap-vue"
import store from "@/store/index"
import Api from "@/services/api/index"
import Datatable from "@/views/components/utilis-spa/datatable/Datatable.vue"
import DatatableFilterUtils from "@/views/components/utilis-spa/datatable/datatableFilterUtil"
import ActionsCreate from "@/views/app/actions/ActionsCreate.vue"
import DatatableHeader from "@/views/components/utilis-spa/datatable/DatatableHeader.vue"
import DatatableFilter from "@/views/components/utilis-spa/datatable/DatatableFilter.vue"

export default {
    components: {
        DatatableFilter,
        DatatableHeader,
        BCard,
        BButton,
        Datatable,
        ActionsCreate,
    },
    data() {
        return {
            dataTableOptions: {
                pagination: {
                    perPage: 5,
                    totalRows: 0,
                },
            },
            isLoading: true,
            dir: false,
            columns: [
                {
                    label: this.$t("modules.actions.title"),
                    field: "title",
                },
                {
                    label: this.$t("modules.actions.objective"),
                    field: "objective",
                },
                {
                    label: this.$t("modules.actions.budget"),
                    field: "budget",
                },
                {
                    label: this.$t("modules.actions.actionCategory"),
                    field: "actionCategory",
                },
                {
                    label: this.$t("modules.actions.goalCategory"),
                    field: "goalCategory",
                },
            ],
            rows: [],
            searchTerm: "",
            availableDatatableFilters: {},
            service: Api,
            apiFilter: null,
            actionCategories: [],
            goalCategories: [],
            showCreateComponent: false,
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },

        statusVariant() {
            const statusColor = {
                Ativo: "light-primary",
                Inativo: "light-danger",
            }

            return status => statusColor[status]
        },
    },
    created() {
        this.getActions()
        this.getAvailableFilters()
    },
    methods: {
        formatDateTime(date) {
            if (date == null) return

            // eslint-disable-next-line consistent-return
            return this.$d(new Date(date), "dateTime")
        },
        handleChangePage(page) {
            this.isLoading = true
            this.getActions({ selectedPage: page.currentPage })
        },
        handlePageSizeChange(pageSize) {
            this.dataTableOptions.pagination.perPage = pageSize
            this.getActions()
        },
        async getActions(options = {}) {
            try {
                this.isLoading = true
                const { pagination: paginationOptions } = this.dataTableOptions
                const { selectedPage } = options

                const actionsResponse = await this.service.findAll("actions", {
                    // RELATED RESOURCES
                    include: "actionCategory,goalCategory,responsible",

                    // FILTERS
                    filter: this.apiFilter,

                    // PAGINATION
                    page: {
                        size: paginationOptions.perPage,
                        number: selectedPage || 1,
                    },
                })

                this.rows = actionsResponse.data
                const paginationMetaData = actionsResponse.meta.page
                this.dataTableOptions.pagination.totalRows =
                    paginationMetaData.total
            } finally {
                this.isLoading = false
            }
        },
        async getAvailableFilters() {
            this.actionCategories = await this.service.findAll(
                "action-categories"
            )

            this.availableDatatableFilters.actionCategory = DatatableFilterUtils.createFilter.list(
                "actionCategory",
                (this.actionCategories = this.actionCategories.data.map(
                    actionCategory => ({
                        value: actionCategory.id,
                        label: actionCategory.description,
                    })
                )),
                {
                    filterAttribute: "id",
                    label: this.$t("modules.actions.actionCategory"),
                    placeholder: this.$t("common.selectOneOrMany", {
                        resource: this.$t(
                            "modules.actions.actionCategory"
                        ).toLowerCase(),
                    }),
                }
            )

            this.goalCategories = await this.service.findAll(
                "goal-categories",
                {
                    fields: { "goal-categories": "description" },
                }
            )

            this.availableDatatableFilters.goalCategory = DatatableFilterUtils.createFilter.list(
                "goalCategory",
                (this.goalCategories = this.goalCategories.data.map(
                    goalCategory => ({
                        value: goalCategory.id,
                        label: goalCategory.description,
                    })
                )),
                {
                    filterAttribute: "id",
                    label: this.$t("modules.actions.goalCategory"),
                    placeholder: this.$t("common.selectOneOrMany", {
                        resource: this.$t(
                            "modules.actions.goalCategory"
                        ).toLowerCase(),
                    }),
                }
            )

            this.availableDatatableFilters.responsible = DatatableFilterUtils.createFilter.searchAsync(
                "responsible",
                {
                    label: this.$t("modules.actions.responsible"),
                    placeholder: this.$t("common.selectOneOrMany", {
                        resource: this.$t(
                            "modules.actions.responsible"
                        ).toLowerCase(),
                    }),
                    filterAttribute: "id",
                },
                {
                    apiData: {
                        resource: "people",
                        fields: { people: "name" },
                        fieldsMapping: {
                            label: "name",
                            value: "id",
                        },
                    },
                }
            )

            this.showCreateComponent = true
        },
        onRowClick(params) {
            this.$router.push({
                name: "view-actions",
                params: { id: params.row.id },
            })
        },
        onFilterUpdate(updatedFilter) {
            this.apiFilter = DatatableFilterUtils.serializer.datatableFilterToJsonApi(
                updatedFilter
            )
            this.getActions()
        },
    },
}
</script>
