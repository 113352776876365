var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$can('create', 'actions') && _vm.showCreateComponent)?_c('actions-create',{attrs:{"id":"create-actions-modal","actionCategories":_vm.actionCategories,"goalCategories":_vm.goalCategories},on:{"addNewactionToList":function (action) { return _vm.rows.unshift(action); }}}):_vm._e(),_c('b-card',[_c('datatable-header',{attrs:{"title":_vm.$tc('modules.actions.description', 2),"genericSearchEnabled":true,"searchTermPlaceHolder":_vm.$t('common.search')},on:{"filterUpdated":_vm.onFilterUpdate},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('b-button',{staticClass:"ml-2",staticStyle:{"background-color":"#519bd6"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$root.$emit(
                            'bv::toggle::modal',
                            'create-actions-modal'
                        )}}},[_vm._v(" "+_vm._s(_vm.$t("common.crud.addNew", { resource: _vm.$tc( "modules.actions.description", 1 ).toLowerCase(), }).toString())+" ")])]},proxy:true},{key:"advancedSearch",fn:function(ref){
                        var expanded = ref.expanded;
                        var onFilterChange = ref.onFilterChange;
return [_c('DatatableFilter',{attrs:{"availableDatatableFilters":_vm.availableDatatableFilters,"expanded":expanded},on:{"filterUpdated":_vm.onFilterUpdate,"onFilterChange":onFilterChange}})]}}])}),_c('datatable',{attrs:{"columns":_vm.columns,"isLoading":_vm.isLoading,"paginationOptions":{
                enabled: true,
                mode: 'pages',
                perPage: _vm.dataTableOptions.pagination.perPage,
            },"rows":_vm.rows,"rtl":_vm.direction,"searchOptions":{
                enabled: true,
                externalQuery: _vm.searchTerm,
            },"totalRows":_vm.dataTableOptions.pagination.totalRows,"mode":"remote","theme":"my-theme","styleClass":"vgt-table"},on:{"on-row-click":_vm.onRowClick,"on-page-change":_vm.handleChangePage,"onPageSizeChange":_vm.handlePageSizeChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'actionCategory')?_c('span',[(props.row.actionCategory)?_c('span',[_vm._v(" "+_vm._s(props.row.actionCategory.description)+" ")]):_vm._e()]):(props.column.field === 'goalCategory')?_c('span',[(props.row.goalCategory)?_c('span',[_vm._v(" "+_vm._s(props.row.goalCategory.description)+" ")]):_vm._e()]):(props.column.field === 'budget')?_c('span',[(props.row.budget)?_c('span',[_vm._v(" "+_vm._s(_vm.$n(props.row.budget, "currency"))+" ")]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }