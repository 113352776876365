<template>
    <b-modal
        v-if="$can('create', 'actions')"
        id="create-actions-modal"
        v-model="active"
        centered
        hideFooter
        size="xl"
        :title="$t('modules.actions.new')"
        @show="setAttributesDefaultValues"
    >
        <template #default>
            <b-card noBody class="p-0 mb-0">
                <b-card-body class="mt-2">
                    <formulateForm
                        v-slot="{ loading, hasErrors }"
                        v-model="formData"
                        name="actionsForm"
                        @submit="handleSubmit"
                    >
                        <b-row class="mt-2">
                            <b-col cols="12" md="4">
                                <formulateInput
                                    :label="
                                        $tc('modules.actions.description', 1)
                                    "
                                    name="title"
                                    :placeholder="$t('modules.actions.title')"
                                    type="text"
                                    validation="required|max:255,length"
                                />
                            </b-col>
                            <b-col cols="12" md="4">
                                <formulateInput
                                    :label="$t('modules.actions.type')"
                                    name="actionCategory"
                                    :placeholder="
                                        $t('common.crud.select') +
                                        ' ' +
                                        $t(
                                            'modules.actions.actionCategory'
                                        ).toLowerCase()
                                    "
                                    type="vue-select"
                                    validation="optional"
                                    :options="actionsCategoriesOptions"
                                    @update:input="
                                        formData.actionCategory = $event
                                    "
                                />
                            </b-col>
                            <b-col cols="12" md="2">
                                <formulate-input
                                    v-model="formData.initialPeriod"
                                    :label="$t('modules.actions.initialPeriod')"
                                    :placeholder="$t('customFormats.date')"
                                    name="initialPeriod"
                                    type="custom-date"
                                    validation="optional|date:YYYY-MM-DD"
                                    @update:date="
                                        formData.initialPeriod = $event
                                    "
                                >
                                </formulate-input>
                            </b-col>
                            <b-col cols="12" md="2">
                                <formulate-input
                                    v-model="formData.finalPeriod"
                                    :label="$t('modules.actions.finalPeriod')"
                                    :placeholder="$t('customFormats.date')"
                                    name="finalPeriod"
                                    type="custom-date"
                                    validation="optional|date:YYYY-MM-DD"
                                    @update:date="formData.finalPeriod = $event"
                                >
                                </formulate-input>
                            </b-col>
                        </b-row>

                        <b-row class="mt-2">
                            <b-col cols="12" md="3">
                                <formulateInput
                                    :label="$t('modules.actions.budgetAccount')"
                                    name="budgetAccount"
                                    placeholder="0"
                                    type="text"
                                    validation="optional|max:255,length"
                                />
                            </b-col>
                            <b-col cols="12" md="3">
                                <formulateInput
                                    :label="$t('modules.actions.budget')"
                                    name="budget"
                                    :placeholder="$n(0.0, 'currency')"
                                    type="text"
                                    validation="optional|number"
                                />
                            </b-col>
                            <b-col cols="12" md="3">
                                <select-user
                                    name="responsible"
                                    :placeholder="
                                        $t('common.crud.select') +
                                        ' ' +
                                        $tc(
                                            'modules.people.description',
                                            1
                                        ).toLowerCase()
                                    "
                                    :label="$t('modules.actions.responsible')"
                                    validation="optional"
                                    :options="responsibleOptions"
                                    @update:options="
                                        responsibleOptions = $event
                                    "
                                    @update:input="
                                        formData.responsible = $event
                                    "
                                ></select-user>
                            </b-col>
                            <b-col cols="12" md="3">
                                <formulateInput
                                    :label="$t('modules.actions.goalCategory')"
                                    name="goalCategory"
                                    :placeholder="
                                        $t('common.crud.select') +
                                        ' ' +
                                        $t(
                                            'modules.actions.goalCategory'
                                        ).toLowerCase()
                                    "
                                    type="vue-select"
                                    validation="optional"
                                    :options="goalCategoriesOptions"
                                    @update:input="
                                        formData.goalCategory = $event
                                    "
                                />
                            </b-col>
                        </b-row>

                        <b-row class="mt-2">
                            <b-col cols="12" md="6">
                                <formulateInput
                                    :label="$t('modules.actions.objective')"
                                    name="objective"
                                    :placeholder="
                                        $t('common.crud.informAttribute', {
                                            attribute: $t(
                                                'modules.actions.actionObjective'
                                            ).toLowerCase(),
                                        })
                                    "
                                    type="textarea"
                                    validation="optional|max:255,length"
                                />
                            </b-col>
                            <b-col cols="12" md="6">
                                <formulateInput
                                    :label="$t('common.crud.description')"
                                    name="description"
                                    :placeholder="
                                        $t('common.crud.informAttribute', {
                                            attribute: $tc(
                                                'modules.actions.actionDescription',
                                                1
                                            ).toLowerCase(),
                                        })
                                    "
                                    type="textarea"
                                    validation="optional|max:255,length"
                                />
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col class="d-flex justify-content-center">
                                <formulateErrors />
                            </b-col>
                        </b-row>

                        <b-row class="float-right mr-0">
                            <b-col>
                                <formulateInput
                                    :disabled="hasErrors || loading"
                                    :label="
                                        loading
                                            ? $t('common.crud.creating')
                                            : $t('common.crud.create')
                                    "
                                    inputClass="btn btn-primary"
                                    type="submit"
                                />
                            </b-col>

                            <b-col class="d-flex justify-content-center">
                                <FormulateInput
                                    :label="$t('common.crud.close')"
                                    inputClass="btn btn-danger"
                                    type="button"
                                    @click="hide"
                                />
                            </b-col>
                        </b-row>
                    </formulateForm>
                </b-card-body>
            </b-card>
        </template>
    </b-modal>
</template>

<script>
import { BCard, BRow, BCol, BCardBody, BModal } from "bootstrap-vue"
import Api from "@/services/api"
import SelectUser from "@/views/components/utilis-spa/vue-select-async/SelectUser.vue"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    name: "ActionsCreateModal",
    components: {
        SelectUser,
        BModal,
        BCard,
        BRow,
        BCol,
        BCardBody,
    },
    props: {
        /* Props used when parent component already consumed the API */
        actionCategories: {
            type: Array,
            required: false,
            default: null,
        },
        goalCategories: {
            type: Array,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            active: false,
            formData: {
                title: "",
                initialPeriod: "",
                finalPeriod: "",
                budgetAccount: "",
                budget: "",
                objective: "",
                description: "",
                actionCategory: "",
                goalCategory: "",
                responsible: "",
            },
            actionsCategoriesOptions: null,
            goalCategoriesOptions: null,
            responsibleOptions: [],
            originsOptions: [],
            emails: [],
            phones: [],
            actionsOptions: [],
        }
    },
    async mounted() {
        if (this.actionCategories && this.goalCategories) {
            this.actionsCategoriesOptions = this.actionCategories
            this.goalCategoriesOptions = this.goalCategories
        } else {
            const systemRecords = await Promise.all([
                this.getActionCategory(),
                this.getGoalCategories(),
            ])

            this.goalCategoriesOptions = systemRecords[0].data.map(el => ({
                value: el.id,
                label: el.description,
            }))

            this.actionsCategoriesOptions = systemRecords[1].data.map(el => ({
                value: el.id,
                label: el.description,
            }))
        }
    },
    methods: {
        getActionCategory() {
            return Api.findAll("action-categories", {
                fields: { "action-categories": "description" },
            })
        },

        getGoalCategories() {
            return Api.findAll("goal-categories", {
                fields: { "goal-categories": "description" },
            })
        },
        show() {
            this.active = true
        },
        hide() {
            this.active = false
            this.setAttributesDefaultValues()
        },

        setAttributesDefaultValues() {
            this.formData.title = ""
            this.formData.initialPeriod = ""
            this.formData.finalPeriod = ""
            this.formData.budgetAccount = ""
            this.formData.budget = ""
            this.formData.objective = ""
            this.formData.description = ""
            this.formData.actionCategory = ""
            this.formData.goalCategory = ""
            this.formData.responsible = ""
        },

        async handleSubmit() {
            try {
                this.loading = true
                const response = await Api.create(
                    "actions",
                    {
                        title: this.formData.title,
                        initialPeriod: this.formData.initialPeriod,
                        finalPeriod: this.formData.finalPeriod,
                        budgetAccount: this.formData.budgetAccount,
                        budget: parseFloat(this.formData.budget),
                        objective: this.formData.objective,
                        description: this.formData.description,
                        actionCategory: this.formData.actionCategory
                            ? { id: this.formData.actionCategory }
                            : null,
                        goalCategory: this.formData.goalCategory
                            ? { id: this.formData.goalCategory }
                            : null,
                        responsible: this.formData.responsible
                            ? { id: this.formData.responsible }
                            : null,
                    },
                    {
                        include:
                            "notes,notes.creator,responsible,actionCategory,goalCategory,creator,editor,destroyer," +
                            "administrators,coordinators,administrators.phones,coordinators.phones",
                    }
                )

                this.$toast(toastMessages.getSuccessMessage())

                this.$emit("addNewActionToList", response.data)
            } catch (e) {
                this.$formulate.handle(e, "actionsForm")
                this.$toast(toastMessages.getUnexpectedError())
            } finally {
                this.loading = false
            }
            this.$bvModal.hide("create-actions-modal")
        },
    },
}
</script>

<style scoped lang="scss">
.header {
    background-color: #519bd6 !important;
    width: 100%;
    height: 4rem;
    color: #ffffff;
    position: relative;
}

.header div {
    color: #ffffff;
    bottom: 50%;
    position: relative;
}

.header h5 {
    color: #ffffff;
    font-size: 2rem;
}

.badge {
    background-color: #ffffff;
    color: #519bd6;
    width: 5rem;
    height: 2rem;
}

.modal-body {
    padding: 0;
}
</style>
